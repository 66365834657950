import hash from "hash.js";

define(function (require) {
  var $ = require("jquery");
  var _ = require("underscore");
  var URI = require("URIjs/URI");
  var PageConfig = require("common/page-config");
  var xhrUris = require("common/xhr-uris");
  var windowLoaded = require("common/ready").windowLoaded;
  var dataLayer = require("common/analytics/dataLayer");

  var deferred, deferredNext;
  const AUTHENTICATED_STATUS = ["PROFILE_AUTHENTICATED", "ACCOUNT_AUTHENTICATED"];

  const getFacebookAccountValue = (account, key) => {
    return account && account[key] ?
      account[key].toLowerCase().replace(/\s/g, "") :
      null;
  };

  const facebookTrackingMappings = {
    em: (account) => getFacebookAccountValue(account, "email"),
    fn: (account) => getFacebookAccountValue(account, "firstName"),
    ln: (account) => getFacebookAccountValue(account, "lastName"),
    ge: (account) => account && account.gender ?
      account.gender.charAt(0).toLowerCase() :
      null,
    ph: (account) => account && account.intlPhoneNumber ?
      account.intlPhoneNumber.replace(/\s|\+/g, "") :
      null
  };

  const getUserDataHashes = (account) => {
    let hashes = {};

    Object.keys(facebookTrackingMappings).forEach((key) => {
      const value = facebookTrackingMappings[key](account);

      if (value) {
        hashes[key] = hash.sha256().update(value).digest("hex");
      } else {
        hashes[key] = null;
      }
    });

    return hashes;
  };

  const track = (account, trackEvent) => {
    const userAuthenticated = _.contains(AUTHENTICATED_STATUS, account.loginStatus);

    if (account && account.loginStatus) {
      dataLayer.track({
        event: trackEvent,
        user: {
          accountId: account.accountId || undefined,
          isAuthenticated: userAuthenticated,
          authenticationState: account.loginStatus.toLowerCase(),
          hashes: userAuthenticated ? getUserDataHashes(account) : undefined
        }
      });
    }
  };

  function generateLocations (account) {
    return {
      "user-location": account.location && account.location.location,
      "user-country": account.location && account.location.country,
      "user-region": account.location && account.location.region,
      "channel-default": account.channelDefaultLocation,
      "channel-country": account.channelCountry
    };
  }

  /**
   * Choose a location from the account, according to preference.
   * The preference is described by a list of location type name strings.
   * The first available location is returned.
   *
   * @example accountInformation.selectLocation(["user-location", "user-country", "channel-treatment"]);
   *
   * @param  {Array} locationTypes   An array of location type names.
   * @return {Object} id, name        the chosen location (or null if none are available).
   */
  function selectLocation (locationTypes) {
    var locationType = _.find(locationTypes, _.bind(function (type) {
      var location = this.locations[type];

      return location && location.locationId && location.name;
    }, this));

    if (locationType) {
      return this.locations[locationType];
    }

    return null;
  }

  function selectEscapeLocation () {
    return this.selectLocation([
      "user-region",
      "user-country",
      "channel-country"
    ]);
  }

  function selectLocalLocation () {
    return this.selectLocation([
      "user-location",
      "user-region",
      "user-country",
      "channel-default"
    ]);
  }

  function fetch (trackEvent = true) {
    deferred = deferredNext || $.Deferred();
    deferredNext = $.Deferred();

    var accountInfo;
    var ipAddressOveride = new URI().query(true)["__ip-address"];

    // exit if channel feature is not available
    if (
      !_.contains(PageConfig.uiChannelFeatures, "MARKETPLACE_ACCOUNT_LOGIN") &&
      !_.contains(PageConfig.uiChannelFeatures, "MARKETPLACE_BROWSE")
    ) {
      accountInfo = {
        loginStatus: "NOT_RECOGNISED"
      };

      deferred.resolve(accountInfo, deferredNext);
      return;
    }

    $.get(xhrUris.accountInformation, {
      "__ip-address": ipAddressOveride
    })
      .done(function (accountInformation) {
        accountInfo = _.extend(accountInformation, {
          locations: generateLocations(accountInformation),
          selectLocation: _.bind(selectLocation, accountInformation),
          selectEscapeLocation: _.bind(selectEscapeLocation, accountInformation),
          selectLocalLocation: _.bind(selectLocalLocation, accountInformation)
        });

        if (trackEvent) {
          windowLoaded(function () {
            track(accountInfo, "authenticationState");
          });
        }

        deferred.resolve(accountInfo, deferredNext);
      });
  }

  function get () {
    if (!deferred) {
      fetch();
    }

    return deferred.promise();
  }

  function refresh (trackEvent) {
    fetch(trackEvent);

    return deferred.promise();
  }

  return {
    get: get,
    refresh: refresh,
    track: track
  };
});

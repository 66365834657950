define(function (require) {
  const _ = require("underscore");
  const Backbone = require("backbone");
  const log = require("common/log");

  // A singleton, to be used for event aggregation/mediation.
  const eventEmitter = {};

  _.extend(eventEmitter, Backbone.Events);

  eventEmitter.trigger = function (event) {
    log.client(log.INFO, "EVENT", event, Array.prototype.slice.call(arguments, 1));
    Backbone.Events.trigger.apply(this, arguments);
  };

  // Add event name constants.
  eventEmitter.RECENT_VENUES_CHANGE = "recent-venues:change";
  eventEmitter.OFFER_POPUP_CLOSED = "offer-popup:closed";
  eventEmitter.BOOKING_MODULE_CLOSED = "booking-module:closed";
  eventEmitter.BROWSE_CURRENT_LOCATION = "browse:got-current-location";

  eventEmitter.PROFILE_AVAILABLE = "profile:available";
  eventEmitter.PROFILE_NOT_AVAILABLE = "profile:not-available";
  eventEmitter.PROFILE_LOGIN = "profile:login";
  eventEmitter.PROFILE_LOGIN_FAILURE = "profile:login-failed";
  eventEmitter.PROFILE_LOGIN_FAILURE_CHANNEL = "profile:login-failed-channel";
  eventEmitter.PROFILE_LOGIN_FAILURE_REQUIRES_PASSWORD_VERIFICATION =
    "profile:login-failed-requires-password-verification";
  eventEmitter.PROFILE_LOGIN_FAILURE_FACEBOOK_AND_NO_PASSWORD = "profile:login-failed-facebook-and-no-password";
  eventEmitter.PROFILE_LOGIN_ERROR = "profile:login-error";
  eventEmitter.PROFILE_LOGOUT = "profile:logout";
  eventEmitter.PROFILE_PASSWORD_REQUEST = "profile:password-request";
  eventEmitter.PROFILE_IMAGE_CHANGED = "profile:image-changed";
  eventEmitter.PROFILE_REQUIRES_RESET = "profile:requires-reset";
  eventEmitter.PROFILE_RATE_LIMITED = "profile:rate-limited";

  eventEmitter.PROMOCODES_INUSE_CHANGE = "promocodes-inuse";
  eventEmitter.PROMOCODE_ADDED = "promocode:added";
  eventEmitter.PROMOCODE_REMOVED = "promocode:removed";
  eventEmitter.PROMOCODE_NOT_EMPTY = "promocode:notempty";

  eventEmitter.DISCOUNT_ADDED = "discount:added";

  eventEmitter.PAYMENT_STORED_CARD_CHANGE = "payment:stored-card-change";
  eventEmitter.PAYMENT_METHOD = "payment:method-change";
  eventEmitter.PAYMENT_TOTAL = "payment:total";
  eventEmitter.PAYMENT_TO_PAY_NOW = "payment:to-pay-now";
  eventEmitter.PAY_AT_VENUE_NOT_ALLOWED = "payment:pay-at-venue-disallowed";

  eventEmitter.SECURE_PAYMENT_REQUIRED = "payment-secure:required";
  eventEmitter.SECURE_PAYMENT_RESULT = "payment-secure:result";

  eventEmitter.EMAIL_ADDRESS_CHANGE = "email-address:change";
  eventEmitter.PHONE_NUMBER_CHANGE = "phone-number:change";
  eventEmitter.DISPLAY_NAME_CHANGE = "display-name:change";

  eventEmitter.SIGN_IN = "signin";

  eventEmitter.TERMS_AND_CONDITIONS_VIEWED = "terms-and-conditions:viewed";

  eventEmitter.VALIDATION_FIELD_INVALID = "validation:field-invalid";

  eventEmitter.CHECKOUT_STARTED = "checkout:started";
  eventEmitter.CHECKOUT_FAILED = "checkout:failed";
  eventEmitter.CHECKOUT_NAME_CHANGED = "checkout:name-changed";
  eventEmitter.LOGIN_FORM_FOCUS = "checkout:login-form-focus";

  eventEmitter.SEARCH_LOCATION_CHANGE = "browse:search-location-change";

  eventEmitter.ACCOUNT_NAVIGATION = "account:navigation";
  eventEmitter.ACCOUNT_VIEW_RENDERED = "account:view-rendered";
  eventEmitter.ACCOUNT_INFORMATION = "account:information";

  eventEmitter.PASSWORD_VALUE_CHANGED = "password:value:changed";

  eventEmitter.POPUP_CLOSED = "popup:closed";

  // Events for the booking module
  _.extend(eventEmitter, {
    CHOICES_SELECTION_CHANGE: "choices:selection-changed",
    FULFILLMENT_TYPES_AVAILABLE: "fulfillment-type:available",
    FULFILLMENT_TYPE_CHANGE: "fulfillment-type:changed",
    FULFILLMENT_TYPE_REVERT_CHANGES: "fulfillment-type:revert-changes"
  });

  // Events for the basket in the booking module
  _.extend(eventEmitter, {
    BASKET_LOADED: "basket:loaded",
    BASKET_UPDATED: "basket:updated",
    BASKET_CANCEL_FULFILLMENT_CHANGE: "basket:cancel-fulfillment-change"
  });

  // Events for the checkout availability page
  _.extend(eventEmitter, {
    CHECKOUT_AVAILABILITY_BASE_CHECKOUT_URI_CHANGED: "checkout-availability:baseCheckoutUriChange",
    CHECKOUT_AVAILABILITY_EMPLOYEE_SELECTED: "checkout-availability:EmployeeSelected",
    CHECKOUT_AVAILABILITY_DAY_SELECTED: "checkout-availability:DaySelected",
    CHECKOUT_AVAILABILITY_TIME_SELECTED: "checkout-availability:TimeSelected",
    CHECKOUT_AVAILABILITY_PRICES: "checkout-availability:Prices",
    CHECKOUT_AVAILABILITY_PAGE_LOADED: "checkout-availability:PageLoaded",
    CHECKOUT_AVAILABILITY_PAGINATE: "checkout-availability:paginate-week",
    CHECKOUT_AVAILABILITY_PAGINATE_LOAD: "checkout-availability:paginate-loading"
  });

  return eventEmitter;
});

/**
 * Provides fully qualified URIs for XHRs.
 *
 * Forces https, as not all lmn pages are served over https (and we don't
 * want to make account related requested over http).
 */
define(function (require) {
  const $ = require("jquery");
  const _ = require("underscore");
  const config = require("common/page-config");

  const $base = $("base");
  const basePrefix = $base.length ? $base.attr("href") : false;
  const pageType = config.pageType;

  const apiPathPrefix = "/api/";
  const commonPathPrefix = "/ajax/";
  const siteMobilePathPrefix = "/ajax/mobile/";
  const rainbowPathPrefix = "/api/v1/";
  const sitePathPrefix = "/ajax/site/";
  const widgetPathPrefix = "/place/0/ajax/";

  const commonUris = {
    accountUnreviewedOrders: "account/unreviewed-orders",
    discountValidation: "checkout/discount-validation",
    giftCardCodeValidation: "account/gift-card-discount-code",
    accountDiscountCodes: "account/discount-codes",
    reschedule: "account/appointment/{{appointmentId}}/reschedule",
    cancelOrder: "account/order/{{orderId}}/cancel",
    accountEmailChannelValidation: "account-email-channel-validation?email-address={{emailAddress}}",
    alternateAppUri: "deeplink"
  };

  const apiUris = {
    serviceDetails: "service/{{offerId}}",
    serviceAvailability: "service/{{offerId}}/availability",
    checkoutAvailability: "checkout/availability",
    checkoutAvailabilityServices: "checkout/availability?proposedServices={{services}}",
    checkoutIdealPaymentIssuers: "checkout/ideal-payment-issuers",
    accountReschedule: "account/reschedule",
    cancelAppointment: "account/appointment/{{appointmentId}}/cancel",
    login: "v2/me/login"
  };

  const siteMobileUris = {
    appointmentOfferDetails: "service/{{offerId}}/{{fulfillmentType}}",
    alternativeTreatmentType: "alternative-treatment-type/",
    checkoutGet: "checkout/",
    locationTemplate: "location/{{locationId}}/",
    mobileAccountInformation: "account-information/",
    service: "service/{{id}}/",
    serviceWithSku: "service/{{id}}/sku/{{skuId}}/",
    serviceDatedTemplate: "service/{{offerId}}/dated/{{month}}/{{year}}/",
    serviceAppointmentTemplate: "service/{{offerId}}/appointment/{{month}}/{{year}}/",
    topTreatment: "top-treatment/",
    topTreatmentType: "top-treatment-type/",
    treatmentSearch: "treatment-search/",
    myVenues: "booked-venue/",
    venueReviews: "reviews/",
    venueSearch: "venue-search/",
    venueSearchCount: "venue-search-count/"
  };

  const rainbowMobileUris = {
    venueMenuTemplate: "venue/{{venueId}}/menu/",
    venueWidgetInfo: "venue/{{venueId}}/widget/"
  };

  const siteUris = {
    accountDiscount: "account-discount/",
    authenticatedCustomer: "authenticated-customer",
    cardIssuerIdentification: "card-issuer-identification/",
    customerStoredPayment: "customer-stored-payment/",
    checkoutUri: "checkout-uri/{{venueId}}",
    externalPlace: "external-place/",
    extranetAccount: "extranet-account/",
    logout: "logout/",
    paymentAuthorisation: "payment-authorisation/",
    profile: "profile/",
    accountInformation: "account-information/",
    accountPassword: "account-password",
    browseMap: "browse-map",
    venueSearchByName: "type-ahead/entity/"
  };

  const widgetUris = {};

  let uriPrefix = basePrefix || `https://${location.host}`;

  function prefixUris (uris, pathPrefix) {
    let prefixedUris = {};

    // Add prefixes to uris.
    _.each(uris, function (uri, name) {
      prefixedUris[name] = uriPrefix + pathPrefix + uri;
    });

    return prefixedUris;
  }

  // Remove trailing slash.
  if (uriPrefix.slice(-1) === "/") {
    uriPrefix = uriPrefix.slice(0, -1);
  }

  let uris = {};

  _.extend(uris, prefixUris(apiUris, apiPathPrefix));
  _.extend(uris, prefixUris(commonUris, commonPathPrefix));
  _.extend(uris, prefixUris(siteMobileUris, siteMobilePathPrefix));
  _.extend(uris, prefixUris(rainbowMobileUris, rainbowPathPrefix));
  _.extend(uris, prefixUris(siteUris, sitePathPrefix));

  if (pageType === "widget") {
    _.extend(uris, prefixUris(widgetUris, widgetPathPrefix));
  }

  return uris;
});
